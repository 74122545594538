import * as React from "react";

export const OpenSvg = () => (
  <svg
    width={"100%"}
    height={"auto"}
    viewBox="0 0 1232 339"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M160.85 338.367C262.715 338.367 321.118 266.835 321.118 169.044C321.118 71.7063 262.715 0.174316 160.85 0.174316C58.0793 0.174316 0.582031 71.7063 0.582031 169.044C0.582031 266.835 58.0793 338.367 160.85 338.367ZM160.85 271.815C101.542 271.815 83.8852 225.636 83.8852 169.044C83.8852 112.905 101.542 66.7262 160.85 66.7262C219.705 66.7262 237.815 112.905 237.815 169.044C237.815 225.636 219.705 271.815 160.85 271.815Z"
      fill="white"
    />
    <path
      d="M361.673 331.123H443.165V213.865H518.772C586.229 213.865 628.334 178.552 628.334 110.642C628.334 42.7313 586.229 7.41805 518.772 7.41805L361.673 7.41805L361.673 331.123ZM443.165 147.313V73.97L499.757 73.97C527.827 73.97 545.031 83.4774 545.031 110.642C545.031 137.806 527.827 147.313 499.757 147.313H443.165Z"
      fill="white"
    />
    <path
      d="M745.135 131.467V73.97L909.025 73.97V7.41805L663.643 7.41805V331.123L911.742 331.123V264.571L745.135 264.571V198.019L886.389 198.019V131.467L745.135 131.467Z"
      fill="white"
    />
    <path
      d="M954.561 331.123H1036.05V138.711H1036.96L1150.14 331.123H1231.63V7.41805L1150.14 7.41805V199.83H1149.24L1036.05 7.41805L954.561 7.41805V331.123Z"
      fill="white"
    />
  </svg>
);
