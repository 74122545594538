import * as React from "react";
import { colors } from "../../models";

interface Props {
  active: boolean;
  hover: boolean;
  diameter: string;
}

export const ButtonRight = ({ active, hover, diameter }: Props) => (
  <svg
    width={diameter}
    height={diameter}
    viewBox="0 0 42 43"
    fill="none"
    cursor={active ? "pointer" : "auto"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="21"
      cy="21.8389"
      r="20"
      stroke={
        active
          ? hover
            ? colors.highlight
            : colors.white
          : colors.whiteDeselect
      }
      strokeWidth="2"
    />
    <path
      d="M30.5072 22.546C30.8977 22.1554 30.8977 21.5223 30.5072 21.1318L24.1432 14.7678C23.7527 14.3773 23.1195 14.3773 22.729 14.7678C22.3385 15.1583 22.3385 15.7915 22.729 16.182L28.3858 21.8389L22.729 27.4957C22.3385 27.8862 22.3385 28.5194 22.729 28.9099C23.1195 29.3005 23.7527 29.3005 24.1432 28.9099L30.5072 22.546ZM29.8 20.8389L12.2 20.8389L12.2 22.8389L29.8 22.8389L29.8 20.8389Z"
      fill={
        active
          ? hover
            ? colors.highlight
            : colors.white
          : colors.whiteDeselect
      }
    />
  </svg>
);
