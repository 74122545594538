import * as React from "react";
import { colors } from "../../models";

export const BigArrowUp = ({ hover }: any) => (
  <svg
    width={"100%"}
    height={"100%"}
    viewBox="0 0 343 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor="pointer"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.1132 166.172L314.648 166.172L314.648 78.1688L342.032 78.1688L171.381 -1.49188e-05L0.729256 78.1688L28.1132 78.1687L28.1132 166.172Z"
      fill={hover ? colors.highlight : colors.white}
    />
  </svg>
);
