import * as React from "react";
import styled from "styled-components";
import { colors, sizes, h2 } from "../models";
import { GridContainer, MidTextWrapper } from "./basics";
import { conclusionContent } from "./content/conclusion-content";

interface SemesterConclusionProps {
  semester: number;
}

const ConclusionContainer = styled.div`
  background: ${colors.highlight};
  padding: 20vh 0;
  border-bottom: 3px solid ${colors.black};
`;

const ConclusionText = styled.div`
  ${h2};

  p {
    color: ${colors.black};
  }
`;

const SemesterConclusion = ({ semester }: SemesterConclusionProps) => {
  const createConclusionContent = () => {
    const semesterContent = conclusionContent[semester];
    return (
      <ConclusionContainer>
        <GridContainer>
          <MidTextWrapper>
            <ConclusionText>{semesterContent}</ConclusionText>
          </MidTextWrapper>
        </GridContainer>
      </ConclusionContainer>
    );
  };
  return <>{createConclusionContent()}</>;
};

export default SemesterConclusion;
