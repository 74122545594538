import styled from "styled-components";

import {
  fullWidthGrid,
  gridContainer,
  imageWidthGrid,
  textLeftGrid,
  textMidGrid,
  textRightGrid,
} from "../models";

export const GridContainer = styled.div`
  ${gridContainer};
`;

export const FullWidthWrapper = styled.div`
  ${fullWidthGrid};
`;

export const ImageWidthWrapper = styled.div`
  ${imageWidthGrid};
`;

export const LeftTextWrapper = styled.div`
  ${textLeftGrid}
`;

export const RightTextWrapper = styled.div`
  ${textRightGrid};
`;

export const MidTextWrapper = styled.div`
  ${textMidGrid}
`;
