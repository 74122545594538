import React, { useEffect, useRef, useState } from "react";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { ButtonLeft } from "../../images/svg/button-left";
import { ButtonRight } from "../../images/svg/button-right";
import { breakpoints, sizes } from "../../models";

interface SlideshowNavProps {
  content: any;
  step: any;
  setStep: any;
}

const NavWrapper = styled.div`
  display: flex;
  height: 32px;

  @media (min-width: ${breakpoints.tabletL}px) {
    height: 42px;
  }
`;

const ButtonWrapper = styled.div`
  margin-right: ${sizes.fine}px;
`;

const SlideshowNav = ({ content, step, setStep }: SlideshowNavProps) => {
  const [updateSize, bounds] = useMeasure();
  const [leftHover, setLeftHover] = useState<boolean>(false);
  const [rightHover, setRightHover] = useState<boolean>(false);
  const currentStep = step[content.id];
  const height: number = bounds.height;

  return (
    <NavWrapper ref={updateSize}>
      <ButtonWrapper
        onClick={() => setStep(content.id, -1, content.images.length)}
        onMouseMove={() => setLeftHover(true)}
        onMouseOut={() => setLeftHover(false)}
      >
        <ButtonLeft
          active={currentStep === 0 ? false : true}
          hover={leftHover}
          diameter={`${height}px`}
        />
      </ButtonWrapper>
      <ButtonWrapper
        onClick={() => setStep(content.id, +1, content.images.length)}
        onMouseMove={() => setRightHover(true)}
        onMouseOut={() => setRightHover(false)}
      >
        <ButtonRight
          active={currentStep === content.images.length - 1 ? false : true}
          hover={rightHover}
          diameter={`${height}px`}
        />
      </ButtonWrapper>
    </NavWrapper>
  );
};

export default SlideshowNav;
