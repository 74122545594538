import React, { useRef, useState } from "react";
import styled from "styled-components";
import { body, breakpoints, colors, h1, sizes, smallBody } from "../models";
import {
  FullWidthWrapper,
  ImageWidthWrapper,
  LeftTextWrapper,
  RightTextWrapper,
} from "./basics";
import { mainContent } from "./content/main-content";
import Slideshow from "./graphics/slideshow";
import SlideshowNav from "./graphics/slideshow-nav";

interface SemesterProps {
  semester: number;
}

const TitleText = styled.div`
  ${h1};
  color: ${colors.white};
`;

const MobileAnnotationColumn = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${sizes.petite}px;
  @media (min-width: ${breakpoints.desktopS}px) {
    display: none;
  }
`;

const DesktopAnnotationColumn = styled.div`
  display: none;
  height: 100%;
  @media (min-width: ${breakpoints.desktopS}px) {
    display: block;
  }
`;

const LeftText = styled.div`
  ${body};
  margin-top: ${sizes.hefty}px;
  margin-bottom: 20vh;

  p {
    margin-top: 0;
  }

  a {
    color: ${colors.white};
    text-decoration: none;
    border-bottom: 2px solid white;
  }

  @media (min-width: ${breakpoints.tabletL}px) {
    padding-right: 15%;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    margin-bottom: 40vh;
  }
`;

const RightTextSticky = styled.div`
  position: sticky;
  top: 60px;
  margin-bottom: 40vh;
`;

const RightText = styled.div`
  ${smallBody};
  p {
    margin-top: 0;
  }
`;

const RightTextItem = styled.div`
  margin-bottom: 1em;
`;

const ButtonWebsiteWrapper = styled.div`
  margin: 0 0 ${sizes.petite}px 0;
  @media (min-width: ${breakpoints.desktopS}px) {
    margin: ${sizes.hefty}px 0 ${sizes.petite}px 0;
  }
`;

const ButtonWebsite = styled.button`
  ${body};
  padding: 5px 20px;
  border: 2px solid ${colors.white};
  border-radius: 5px;
  background: none;

  &:hover {
    cursor: pointer;
    border: 2px solid ${colors.highlight};
    color: ${colors.highlight};
  }
`;

const SemesterContent = ({ semester }: SemesterProps) => {
  const [step, setStep] = useState<any>({
    klimagrafik: 0,
    datatalks: 0,
    zeitmaschinen: 0,
    wasserstoff: 0,
    wortwahl: 0,
    mapping: 0,
    networks: 0,
    citizen: 0,
    kdm: 0,
  });

  const handleStepButton = (id: string, move: number, length: number) => {
    const currentStep = step[id];
    const tooLow = currentStep === 0 && move === -1;
    const tooHigh = currentStep === length - 1 && move === 1;
    if (!tooLow && !tooHigh) {
      setStep((prevState: any) => {
        return {
          ...prevState,
          [id]: currentStep + move,
        };
      });
    }
  };

  const createSemesterContent = () => {
    const semesterContent = mainContent[semester];
    return semesterContent.map((content: any, i: number) => {
      return (
        <>
          <FullWidthWrapper id={`${content.id}`}>
            <TitleText>{content.title}</TitleText>
          </FullWidthWrapper>
          <ImageWidthWrapper>
            <Slideshow
              content={content}
              step={step}
              setStep={handleStepButton}
            />
            <MobileAnnotationColumn>
              <div>
                <ButtonWebsiteWrapper>
                  <a href={content.link} target="_blank">
                    <ButtonWebsite>
                      <span> Zur Website </span>
                      <span className="material-symbols-outlined size">
                        open_in_new
                      </span>
                    </ButtonWebsite>
                  </a>
                </ButtonWebsiteWrapper>
                <RightText>
                  <RightTextItem>
                    <b>Kursname:</b>
                    <br />
                    {content.course}
                  </RightTextItem>
                  <RightTextItem>
                    <b>Kursleitung:</b>
                    <br />
                    {content.prof}
                  </RightTextItem>
                  <RightTextItem>
                    <b>Ausführung:</b>
                    <br />
                    {content.team}
                  </RightTextItem>
                </RightText>
              </div>
              <div>
                <SlideshowNav
                  content={content}
                  step={step}
                  setStep={handleStepButton}
                />
              </div>
            </MobileAnnotationColumn>
            <LeftText>{content.text}</LeftText>
          </ImageWidthWrapper>

          <RightTextWrapper>
            <DesktopAnnotationColumn>
              <SlideshowNav
                content={content}
                step={step}
                setStep={handleStepButton}
              />
              <RightTextSticky>
                <ButtonWebsiteWrapper>
                  <a href={content.link} target="_blank">
                    <ButtonWebsite>
                      <span> Zur Website </span>
                      <span className="material-symbols-outlined size">
                        open_in_new
                      </span>
                    </ButtonWebsite>
                  </a>
                </ButtonWebsiteWrapper>
                <RightText>
                  <RightTextItem>
                    <b>Kursname:</b>
                    <br />
                    {content.course}
                  </RightTextItem>
                  <RightTextItem>
                    <b>Kursleitung:</b>
                    <br />
                    {content.prof}
                  </RightTextItem>
                  <RightTextItem>
                    <b>Ausführung:</b>
                    <br />
                    {content.team}
                  </RightTextItem>
                </RightText>
              </RightTextSticky>
            </DesktopAnnotationColumn>
          </RightTextWrapper>
        </>
      );
    });
  };
  return <>{createSemesterContent()}</>;
};

export default SemesterContent;
