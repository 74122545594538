import { createFontSizeScale } from "../util";
import "../css/typography.css";
import "@fontsource/roboto";
import "@fontsource/roboto-mono";

export enum sizes {
  hair = 1,
  dblhair = 2,
  bristle = 3,
  micro = 4,
  tiny = 6,
  thin = 8,
  fine = 10,
  little = 14,
  base = 16,
  small = 18,
  petite = 20,
  junior = 24,
  compact = 28,
  twice = 32,
  medium = 36,
  hefty = 40,
  big = 48,
  large = 56,
  wide = 72,
  huge = 90,
  mediumhuge = 154,
  extremehuge = 200,
}

export const colors = {
  transparent: "rgba(0, 0, 0, 0)",
  white: "#FFF",
  whiteTransparent: "rgba(255, 255, 255, 0.85)",
  whiteDeselect: "rgba(255, 255, 255, 0.4)",
  black: "#202020",
  highlight: "#FAFF00",
  highlightText: "rgba(255, 88, 0, 0.6)",
};

export const fonts = {
  base: "'Roboto', sans-serif",
  annotation: "'Roboto Mono', monospace",
  title: "'Neue Montreal', Helvetica, Arial, sans-serif",
  weightRegular: "normal",
  weightBold: "bold",
  fontStretchCondensed: "70%",
};

const baseFontSize = 16;
const { above, below } = createFontSizeScale(baseFontSize, 1.25, {
  above: 6,
  below: 3,
});
const [small, micro, macro] = below.map((s) => `${s}px`);
const [midSize, big, large, huge, gigantic, mega] = above.map((s) => `${s}px`);

/*
  {
    macro: "8.192px",
    micro: "10.24px",
    small: "12.8px",
    base: "16px",
    midSize: "20px",
    big: "25px",
    large: "31.25px",
    huge: "39.0625px",
    gigantic: "48.828125px"
    mega: "61.04px"
  }
*/
export const fontSizes = {
  macro,
  micro,
  small,
  base: `${baseFontSize}px`,
  midSize,
  big,
  large,
  huge,
  gigantic,
  mega,
};

export const lineHeights = {
  small: `1.2em`,
  body: `1.5em`,
};

export const strokes = {
  thin: 0.75,
  base: 1,
  big: 1.5,
};

export const elementSizes = {
  menuBarHeight: sizes.large,
  progressBarHeight: sizes.tiny,
};

export const breakpoints = {
  smartphone: 0,
  tabletL: 768,
  desktopS: 1200,
  desktopL: 1440,
  desktopXL: 1920,
};

export const gutterWidths = {
  smartphone: 16,
  tabletL: 24,
  desktopS: 24,
  desktopL: 32,
  desktopXL: 32,
};

export const gridMargin = {
  smartphone: 16,
  tabletL: 24,
  desktopS: 32,
  desktopL: 56,
  desktopXL: 72,
};

export const projectIds = [
  "klimagrafik",
  "datatalks",
  "zeitmaschinen",
  "wasserstoff",
  "wortwahl",
  "mapping",
  "networks",
  "citizen",
  "kdm",
];
