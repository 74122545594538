import React, { useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { BigArrowUp } from "../../images/svg/big-arrow-up";
import { colors, h2, heroGrid, overline, sizes, smallBody } from "../../models";
import { GridContainer, MidTextWrapper } from "../basics";

const SemesterSticky = styled.div`
  ${overline};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${colors.black};
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${colors.highlight};

  padding: ${sizes.fine}px;
`;

const ConclusionContainer = styled.div`
  background: ${colors.highlight};
  padding: 20vh 0;
  border-bottom: 3px solid ${colors.black};
`;

const ConclusionText = styled.div`
  ${h2};

  p {
    color: ${colors.black};
  }
`;

const FooterFlexBox = styled.div`
  ${heroGrid};
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${sizes.hefty}px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  gap: ${sizes.fine}px;
`;

const ArrowText = styled.div<any>`
  ${h2};
  text-align: center;
  color: ${(props) => (props.hover ? colors.highlight : colors.white)};
`;

const Footer = () => {
  const [upHover, setUpHover] = useState<boolean>(false);

  return (
    <>
      <SemesterSticky>
        <div>Gesamtstudium</div>
      </SemesterSticky>
      <ConclusionContainer>
        <GridContainer>
          <MidTextWrapper>
            <ConclusionText>
              <p>
                Nach einer Orientierungs&shy;phase zu Beginn des
                Haupt&shy;studiums, setzte ich mich schrittweise immer
                intensiver mit dem Thema Informations&shy;visualisierung im Web
                außeinander. Durch mein Praktikum bestätigt, beschloss ich mich
                für den Rest des Studiums auf diesen Bereich zu fokussieren.
                Gerade an der inhalt&shy;lichen Komplexität, der
                gesellschaft&shy;lichen Relevanz, sowie den gestalterischen und
                technischen Heraus&shy;forderungen von
                Daten&shy;visualisierungen fand ich großen Gefallen.
              </p>
              <p>
                Die letzten zwei Semester im Studium und die Arbeit
                während&shy;dessen bei Figures gaben mir die Möglichkeit
                Sicherheit in gestalter&shy;ischen Abläufen und dem Umgang mit
                Daten zu erlangen. Zum Abschluss des Studiums fühle ich mich
                fähig Visualisierungs&shy;projekte spannend zu konzipieren,
                Daten technisch aufzubereiten, passende Gestaltungs&shy;formen
                zu finden und bis zu einem gewissen Maße auch diese in Code zu
                übersetzen.
              </p>
              <p>
                Für die kommende Zeit möchte ich daran arbeiten individuellere
                Visualisierungs&shy;formen in einem eigenen Stil zu entwickeln.
                Außerdem möchte ich mich inhaltlich stärker mit den Bereichen
                des Story&shy;tellings und dem Nutzen von
                Informations&shy;visualisierungen für akti&shy;vistische Zwecke
                auseinander&shy;setzen und dabei auch medien&shy;übergreifend
                arbeiten.
              </p>
            </ConclusionText>
          </MidTextWrapper>
        </GridContainer>
      </ConclusionContainer>
      <GridContainer>
        <FooterFlexBox>
          <Link to={`hero`} smooth={true}>
            <ArrowWrapper
              onMouseMove={() => setUpHover(true)}
              onMouseOut={() => setUpHover(false)}
            >
              <BigArrowUp hover={upHover} />
              <ArrowText hover={upHover}>NACH OBEN</ArrowText>
            </ArrowWrapper>
          </Link>
        </FooterFlexBox>
      </GridContainer>
    </>
  );
};

export default Footer;
