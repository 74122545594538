import React, { useState } from "react";
import styled from "styled-components";
import useMeasure from "react-use-measure";
import { Link } from "react-scroll";
import { mainContent } from "../content/main-content";
import * as d3 from "d3";
import { colors, fonts, fontSizes, graphicBody } from "../../models";

interface MenuProps {
  isTitleMenu: boolean;
  menuOpen: boolean;
  handleBurgerMenu: any;
}

const MenuWrapper = styled.div<any>`
  position: ${(props) => (props.isTitleMenu ? "absolute" : "sticky")};
  margin-bottom: ${(props) => (props.isTitleMenu ? "auto" : "-100vh")};
  top: 0;
  width: 100%;
  height: 100vh;
  display: ${(props) => (props.visible ? "block" : "none")};
  z-index: 1000;
`;

const MenuButton = styled.a`
  ${graphicBody};
  color: ${colors.black};
  background: ${colors.highlight};
`;

const Menu = ({ isTitleMenu, menuOpen, handleBurgerMenu }: MenuProps) => {
  const [updateSize, bounds] = useMeasure();
  const [mouse, setMouse] = useState<any>({ x: 0, y: 0 });
  const width: number = bounds.width;
  const height: number = bounds.height;

  const createMenuGraphic = () => {
    const margins =
      width >= 768
        ? { top: 50, right: 50, bottom: 50, left: 50 }
        : { top: 20, right: 20, bottom: 20, left: 20 };
    const chartWidth = width - margins.left - margins.right;
    const chartHeight = height - margins.top - margins.bottom;
    const circleRadius = width >= 768 ? 30 : 20;
    const widthCorridor = chartWidth / 5;
    const heightCorridor = chartHeight / 5;

    const firstX = d3
      .scaleLinear()
      .domain([0, height])
      .range(width >= 768 ? [0, widthCorridor * 0.5] : [20, 20]);

    const firstY = d3
      .scaleLinear()
      .domain([0, width])
      .range(width >= 768 ? [0, heightCorridor] : [20, 20]);

    const secondX = d3
      .scaleLinear()
      .domain([0, height])
      .range(
        width >= 768
          ? [widthCorridor * 2, widthCorridor * 1.5]
          : [chartWidth - 20, chartWidth - 20]
      );

    const secondY = d3
      .scaleLinear()
      .domain([0, width])
      .range(width >= 768 ? [heightCorridor * 2, 20] : [50, 50]);

    const thirdX = d3
      .scaleLinear()
      .domain([0, height])
      .range(
        width >= 768 ? [widthCorridor * 0.4, widthCorridor * 1.4] : [30, 30]
      );

    const thirdY = d3
      .scaleLinear()
      .domain([0, width])
      .range(
        width >= 768
          ? [heightCorridor * 4.5, heightCorridor * 4]
          : [chartHeight * 0.3, chartHeight * 0.3]
      );

    const fourthX = d3
      .scaleLinear()
      .domain([0, height])
      .range(
        width >= 768
          ? [widthCorridor * 3, widthCorridor * 4.5]
          : [chartWidth - 20, chartWidth - 20]
      );

    const fourthY = d3
      .scaleLinear()
      .domain([0, width])
      .range(
        width >= 768
          ? [heightCorridor * 0.2, heightCorridor * 1.2]
          : [chartHeight * 0.5, chartHeight * 0.5]
      );

    const fifthX = d3
      .scaleLinear()
      .domain([0, height])
      .range(width >= 768 ? [widthCorridor * 4, widthCorridor * 3] : [10, 10]);

    const fifthY = d3
      .scaleLinear()
      .domain([0, width])
      .range(
        width >= 768
          ? [heightCorridor * 4, chartHeight]
          : [chartHeight - 20, chartHeight - 20]
      );

    const sixthX = d3
      .scaleLinear()
      .domain([0, height])
      .range(
        width >= 768
          ? [widthCorridor * 4.5, chartWidth]
          : [chartWidth - 10, chartWidth - 10]
      );

    const sixthY = d3
      .scaleLinear()
      .domain([0, width])
      .range(
        width >= 768
          ? [heightCorridor * 2, heightCorridor * 2]
          : [chartHeight * 0.8, chartHeight * 0.8]
      );

    const xScales = [firstX, secondX, thirdX, fourthX, fifthX, sixthX];
    const yScales = [firstY, secondY, thirdY, fourthY, fifthY, sixthY];

    const chartContent = Object.keys(mainContent).map((d: any, i: number) => {
      return (
        <>
          <defs>
            <filter
              id="shadow"
              x="-100%"
              y="-100%"
              width="300%"
              height="300%"
              color-interpolation-filters="sRGB"
            >
              <feDropShadow
                dx="2"
                dy="2"
                stdDeviation="10"
                flood-opacity="0.5"
              />
            </filter>
            <marker
              id="arrow"
              refX={12 / 2}
              refY={12 / 2}
              markerWidth={12}
              markerHeight={12}
              orient={"auto-start-reverse"}
            >
              <path
                d={"M2 2 L6 6  L2 10 "}
                stroke={colors.highlight}
                fill={"none"}
                strokeLinecap={"round"}
              />
            </marker>
          </defs>
          <line
            x1={xScales[i](mouse.y)}
            y1={yScales[i](mouse.x)}
            x2={xScales[i + 1](mouse.y)}
            y2={yScales[i + 1](mouse.x)}
            stroke={colors.highlight}
            strokeWidth={7}
            filter={"url(#shadow)"}
          />
          <line
            x1={xScales[i](mouse.y)}
            y1={yScales[i](mouse.x)}
            x2={xScales[i + 1](mouse.y)}
            y2={yScales[i + 1](mouse.x)}
            stroke={colors.highlight}
            strokeWidth={7}
            markerEnd={i === 4 ? "url(#arrow)" : ""}
          />
          <Link to={`${d}semester`} smooth={true} ignoreCancelEvents={true}>
            <g>
              <circle
                key={d}
                className={"menu-circle"}
                cx={xScales[i](mouse.y)}
                cy={yScales[i](mouse.x)}
                r={circleRadius}
                fill={colors.highlight}
                filter={"url(#shadow)"}
                cursor="pointer"
                onClick={() => (isTitleMenu ? null : handleBurgerMenu())}
              />

              <text
                x={xScales[i](mouse.y)}
                y={yScales[i](mouse.x)}
                dy={"0.3em"}
                textAnchor={"middle"}
                fontFamily={fonts.title}
                fontWeight={fonts.weightBold}
                fontSize={width >= 768 ? fontSizes.large : fontSizes.base}
                pointerEvents="none"
              >
                {d}
              </text>
            </g>
          </Link>

          {mainContent[d].map((entry: any, entryIndex: number) => {
            const positionMultiplicator =
              entryIndex === 1 ? 0.66 : i === 0 ? 0.5 : 0.33;
            const textXPosition =
              xScales[i](mouse.y) +
              (xScales[i + 1](mouse.y) - xScales[i](mouse.y)) *
                positionMultiplicator;
            const textYPosition =
              yScales[i](mouse.x) +
              (yScales[i + 1](mouse.x) - yScales[i](mouse.x)) *
                positionMultiplicator;

            const rectHeight = width >= 768 ? 28 : 20;
            const rectWidths = [
              [110],
              [200, 138],
              [208, 190],
              [138, 198],
              [140, 155],
            ];
            const rectWidthMultiplicator = width >= 768 ? 1 : 0.81;
            const rectWidth = rectWidths[i][entryIndex];
            const rectBorderRadius = width >= 768 ? 15 : 10;

            return (
              <>
                <Link to={entry.id} smooth={true}>
                  <rect
                    className={"menu-rect"}
                    x={textXPosition - (rectWidth * rectWidthMultiplicator) / 2}
                    y={textYPosition - rectHeight * 0.68}
                    width={rectWidth * rectWidthMultiplicator}
                    height={rectHeight}
                    rx={rectBorderRadius}
                    fill={colors.highlight}
                    filter={"url(#shadow)"}
                    cursor={"pointer"}
                    onClick={() => (isTitleMenu ? null : handleBurgerMenu())}
                  />
                </Link>
                <text
                  x={textXPosition}
                  y={textYPosition}
                  strokeWidth={0}
                  textAnchor={"middle"}
                  fontFamily={fonts.title}
                  fontWeight={"600"}
                  fontSize={width >= 768 ? fontSizes.base : fontSizes.small}
                  pointerEvents={"none"}
                >
                  {entry.title}
                </text>
              </>
            );
          })}
        </>
      );
    });

    return (
      <g transform={`translate(${margins.left}, ${margins.top})`}>
        {chartContent}
      </g>
    );
  };

  return (
    <MenuWrapper
      ref={updateSize}
      isTitleMenu={isTitleMenu}
      visible={isTitleMenu ? true : menuOpen}
      onMouseMove={(e: any) => setMouse({ x: e.clientX, y: e.clientY })}
    >
      <svg width={width} height={height}>
        {createMenuGraphic()}
      </svg>
    </MenuWrapper>
  );
};

export default Menu;
