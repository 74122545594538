import * as React from "react";
import styled from "styled-components";
import { BigArrowDown } from "../../images/svg/big-arrow-down";
import { OpenSvg } from "../../images/svg/open";
import { breakpoints, heroGrid, sizes, smallBody } from "../../models";
import { GridContainer } from "../basics";
import Menu from "../graphics/menu";

const Section = styled.section`
  height: 100vh;
`;

const TitleFlexBox = styled.div`
  ${heroGrid};
  padding-top: ${sizes.hefty}px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${sizes.hefty}px;
  @media (min-width: ${breakpoints.tabletL}px) {
    height: 100vh;
  }
`;

const TitleAnnotation = styled.div`
  ${smallBody};
  padding-bottom: 20px;
  margin-left: 54%;
  @media (min-width: ${breakpoints.tabletL}px) {
    margin-left: 77.5%;
    padding-bottom: 0;
  }
`;

const ArrowWrapper = styled.div`
  width: 70vw;
  @media (min-width: ${breakpoints.tabletL}px) {
    width: 20vw;
  }
`;
const OpenWrapper = styled.div`
  width: 100%;
`;

const HeroSection = () => {
  return (
    <Section id="hero">
      <Menu isTitleMenu={true} menuOpen={true} handleBurgerMenu={null} />
      <GridContainer>
        <TitleFlexBox>
          <OpenWrapper>
            <TitleAnnotation>
              <b>Werkschau</b>
              <br />
              von Tim Hönig
            </TitleAnnotation>
            <OpenSvg />
          </OpenWrapper>
          <ArrowWrapper>
            <BigArrowDown />
          </ArrowWrapper>
        </TitleFlexBox>
      </GridContainer>
    </Section>
  );
};

export default HeroSection;
