import { css } from "styled-components";
import {
  fonts,
  colors,
  fontSizes,
  strokes,
  breakpoints,
  lineHeights,
} from "../models";
import { sizes } from "./constants";

export const baseFontProps = {
  "font-family": fonts.base,
  "font-weight": fonts.weightRegular,
  "font-size": fontSizes.base,
  "line-height": `${lineHeights.body}`,
  color: colors.white,
};

export const annotationFontProps = {
  "font-family": fonts.annotation,
  "font-weight": fonts.weightRegular,
  "font-size": fontSizes.base,
  "line-height": `${lineHeights.body}`,
  color: colors.white,
};

export const titleFontProps = {
  "font-family": fonts.title,
  "font-weight": fonts.weightRegular,
  "font-size": fontSizes.base,
};

// -03 macro
export const macroRegular = {
  ...baseFontProps,
  "font-size": fontSizes.macro,
};

// -02 micro
export const microRegular = {
  ...baseFontProps,
  "font-size": fontSizes.micro,
};

// -01 small
export const smallRegular = {
  ...baseFontProps,
  "font-size": fontSizes.small,
};

export const smallRegularAnnotation = {
  ...annotationFontProps,
  "font-size": fontSizes.small,
};

export const smallBoldTitle = {
  ...titleFontProps,
  "font-size": fontSizes.small,
  "font-weight": fonts.weightBold,
};

// 00 base
export const baseRegular = {
  ...baseFontProps,
};

export const baseRegularAnnotation = {
  ...annotationFontProps,
};

export const baseBoldTitle = {
  ...titleFontProps,
  "font-weight": fonts.weightBold,
};

// +01 midSize
export const midSizeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.midSize,
};

export const midSizeRegularAnnotation = {
  ...annotationFontProps,
  "font-size": fontSizes.midSize,
};

export const midSizeBoldTitle = {
  ...titleFontProps,
  "font-size": fontSizes.midSize,
  "font-weight": fonts.weightBold,
};

// +02 big
export const bigRegular = {
  ...baseFontProps,
  "font-size": fontSizes.big,
};

// +03 large
export const largeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.large,
};

export const largeBoldTitle = {
  ...titleFontProps,
  "font-size": fontSizes.large,
  "font-weight": fonts.weightBold,
};

// +04 huge
export const hugeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.huge,
};

export const hugeBoldTitle = {
  ...titleFontProps,
  "font-size": fontSizes.huge,
  "font-weight": fonts.weightBold,
};

// +05 gigantic
export const giganticRegular = {
  ...titleFontProps,
  "font-size": fontSizes.gigantic,
  "line-height": lineHeights.small,
};

// +06 mega
export const megaBold = {
  ...baseFontProps,
  "font-size": fontSizes.mega,
  "font-weight": fonts.weightBold,
  "line-height": lineHeights.small,
};

// STORY
/*
  {
    macro: "8.192px",
    micro: "10.24px",
    small: "12.8px",
    base: "16px",
    midSize: "20px",
    big: "25px",
    large: "31.25px",
    huge: "39.0625px",
    gigantic: "48.828125px"
    mega: "61.04px"
  }
*/

export const h1 = css`
  ${giganticRegular};
  margin: ${sizes.hefty}px 0 ${sizes.fine}px 0;
  @media (min-width: ${breakpoints.tabletL}px) {
    font-size: 76.3px;
    margin: ${sizes.huge}px 0 ${sizes.petite}px 0;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    font-size: 95.38px;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    font-size: 119.25px;
  }
`;

export const h2 = css`
  ${midSizeBoldTitle};
  hyphens: manual;
  @media (min-width: ${breakpoints.tabletL}px) {
    ${largeBoldTitle};
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    ${hugeBoldTitle};
  }
`;

export const body = css`
  ${smallRegular};
  hyphens: manual;
  @media (min-width: ${breakpoints.tabletL}px) {
    ${midSizeRegular};
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    ${bigRegular};
  }
`;

export const smallBody = css`
  ${smallRegularAnnotation};
  @media (min-width: ${breakpoints.tabletL}px) {
    ${baseRegularAnnotation};
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${midSizeRegularAnnotation};
  }
`;

export const graphicBody = css`
  ${smallRegular};
  @media (min-width: ${breakpoints.tabletL}px) {
    ${baseRegular};
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${midSizeRegular};
  }
`;

export const overline = css`
  ${smallBoldTitle};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  @media (min-width: ${breakpoints.tabletL}px) {
    ${baseBoldTitle};
  }
`;
