import React, { useState } from "react";
import styled from "styled-components";

interface SlideshowProps {
  content: any;
  step: any;
  setStep: any;
}

const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  // max-height: 50vh;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  z-index: 2;
`;

const Button = styled.div`
  height: 100%;
  width: 50%;
`;

const Slides = styled.div<any>`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  transform: ${(props) => `translateX(${props.step * -100}%)`};

  transition: transform 0.5s ease-out;
`;

const Image = styled.img`
  max-width: 100%;
`;

const Slideshow = ({ content, step, setStep }: SlideshowProps) => {
  const createSlideshow = () => {
    const images = content.images;
    if (images.length > 0) {
      return images.map((image: string) => {
        return <Image src={require(`../../images/${image}`).default} />;
      });
    }
  };

  const swipe = (direction: string) => {
    const move = direction === "left" ? -1 : 1;
    setStep(content.id, move);
  };

  return (
    <SlideshowContainer>
      <ButtonContainer>
        <Button
          onClick={() => setStep(content.id, -1, content.images.length)}
        ></Button>
        <Button
          onClick={() => setStep(content.id, 1, content.images.length)}
        ></Button>
      </ButtonContainer>
      <Slides step={step[content.id]}>{createSlideshow()}</Slides>
    </SlideshowContainer>
  );
};

export default Slideshow;
