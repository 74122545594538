import React from "react";

export const conclusionContent: any = {
  4: (
    <p>
      Insgesamt belegte ich in diesem Semester drei Kurse, die sich stark
      voneinander unter&shy;schieden. Ich erkannte mein Interesse für
      Informations&shy;visualisierung und meine Defizite im UI-Design. Mit
      diesem Wissen wählte ich die Kurse für die kommenden Semester.
    </p>
  ),
  5: (
    <p>
      Neben den vorge&shy;stellten Kursen belegte ich in diesem Semester noch
      einen Kurs zum Thema Social Design. Rück&shy;blickend markieren alle Kurse
      aus dem Semester eine Facette meines heutigen Anspruchs an meine
      gestal&shy;terische Tätigkeit: Mithilfe von spannendem Story&shy;telling
      und ausge&shy;feilter Daten&shy;visualisierung gesell&shy;schaftlich
      relevante Informationen vermitteln.
    </p>
  ),
  6: (
    <p>
      Das Praktikum war ein sehr wichtiger Punkt in meinem Studium, da es mich
      darin bestärkte mich noch stärker auf die Bereiche
      Informations&shy;visualisierung und UI-Design zu konzen&shy;trieren und
      mein verbleibendes Studium darauf auszurichten.
    </p>
  ),
  7: (
    <p>
      In diesem Semester konnte ich erstmals meine im Praktikum erlernten
      Fähig&shy;keiten einbringen und verbessern. Auch wenn sich die Prozesse
      noch häufig überfordernd anfühlten, wurde ich sicherer im Umgang mit
      Daten, Webdesign und Web&shy;development.
    </p>
  ),
  8: (
    <p>
      Meine Vor&shy;erfahrung half mir in den Semester&shy;projekten einen
      Schritt weiter zu gehen und individuellere Formen der
      Daten&shy;visualisierung auszu&shy;probieren und umzu&shy;sezten. Ich
      fühle mich nach diesem Semester so sicher im Umgang mit den
      Werk&shy;zeugen, dass ich an meine nächsten Projekte experimen&shy;teller
      und forschender herangehen möchte.
    </p>
  ),
};
