import React, { useState } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import { body, colors, fullWidthGrid, overline, sizes } from "../../models";
import { GridContainer, FullWidthWrapper } from "../basics";
import Menu from "../graphics/menu";
import Burger from "../burger";
import SemesterContent from "../semester";
import SemesterConclusion from "../semester-conclusion";
import Footer from "./footer";
import useMeasure from "react-use-measure";

const SemesterSticky = styled.div`
  ${overline};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${colors.black};
  position: sticky;
  top: 0;
  z-index: 100;
  background: ${colors.highlight};

  padding: ${sizes.fine}px;
`;

const MainSection = () => {
  const [updateSize, bounds] = useMeasure();
  const width = bounds.width;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const semesters = [4, 5, 6, 7, 8];

  const handleBurgerMenu = () => {
    const chartHeight = width >= 768 ? 20 - 4 : 15 - 3;
    if (!menuOpen) {
      d3.select("#first-line")
        .transition()
        .duration(250)
        .ease(d3.easeQuadOut)
        .attr("y2", chartHeight);
      d3.select("#second-line")
        .transition()
        .duration(250)
        .ease(d3.easeQuadOut)
        .attr("visibility", "hidden");
      d3.select("#third-line")
        .transition()
        .duration(250)
        .ease(d3.easeQuadOut)
        .attr("y2", 0);
      setMenuOpen(true);
    } else {
      d3.select("#first-line")
        .transition()
        .duration(250)
        .ease(d3.easeQuadOut)
        .attr("y2", 0);
      d3.select("#second-line")
        .transition()
        .duration(250)
        .ease(d3.easeQuadOut)
        .attr("visibility", "visible");
      d3.select("#third-line")
        .transition()
        .duration(250)
        .ease(d3.easeQuadOut)
        .attr("y2", chartHeight);
      setMenuOpen(false);
    }
  };

  const createSemesterContainers = () => {
    return semesters.map((semester: number) => {
      return (
        <>
          <SemesterSticky ref={updateSize}>
            <div>{semester}. Semester</div>
          </SemesterSticky>
          <GridContainer id={`${semester}semester`}>
            <SemesterContent semester={semester} />
          </GridContainer>
          <SemesterConclusion semester={semester} />
        </>
      );
    });
  };

  return (
    <>
      <Menu
        isTitleMenu={false}
        menuOpen={menuOpen}
        handleBurgerMenu={handleBurgerMenu}
      />
      <Burger width={width} handleBurgerMenu={handleBurgerMenu} />
      {createSemesterContainers()}
      <Footer />
    </>
  );
};

export default MainSection;
