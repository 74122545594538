import React from "react";
import styled from "styled-components";
import { fonts } from "../../models";

const subNumber = styled.sub`
  font-size: 10px;
`;

export const mainContent: any = {
  4: [
    {
      id: "klimagrafik",
      project: "Minimize Carbon Footprints",
      title: "Klimagrafik",
      course: "Klimagrafik",
      prof: "Prof. Boris Müller",
      team: <>Partnerarbeit mit Jonathan&nbsp;Schmitz</>,
      link: "https://minimizecarbonfootprints.webflow.io/",
      images: [
        "MinimizeCarbonFootprints_Start.jpg",
        "MinimizeCarbonFootprints_mec.jpg",
        "MinimizeCarbonFootprints_mec_dropdown_measures.jpg",
        "MinimizeCarbonFootprints_hec_dropdown_measures.jpg",
      ],
      text: (
        <>
          <p>
            In diesem Kurs, der am Beginn meines Haupt&shy;studiums stand, ging
            es darum sich mit Klima&shy;themen auseinander&shy;zusetzen und ein
            Thema in Form einer Daten&shy;visualisierung im Web zu
            kommunizieren. Mein Team&shy;partner und ich entschlossen uns dazu
            den CO2-Rechner des deutschen Umwelt&shy;bundesamtes als
            Daten&shy;grundlage zu verwenden. Abhängig von unterschiedlichen
            Typen von Ver&shy;braucher*innen zeigten wir, welche Emitter wie
            viel CO2 verursachen und was wirkungsvolle politische Maßnahmen zur
            CO2-Reduktion sind.
          </p>
          <p>
            Der Prozess des Kurs&shy;projektes gestaltete sich sehr zäh und
            anstrengend. Sowohl mein Team&shy;partner als auch ich selbst hatten
            nahezu keine Erfahrung im Umgang mit Daten, UI-Design und
            Web&shy;entwicklung. Gerade deswegen lernte ich in diesen Bereichen
            enorm viel und fand Gefallen an der Kom&shy;plexität, die
            Daten&shy;visualisierungs&shy;projekte mit sich bringen.
          </p>
        </>
      ),
    },
  ],
  5: [
    {
      id: "datatalks",
      project: "Touch Touch Swipe",
      title: "Data Talks / Data Walks",
      course: "Data Talks / Data Walks",
      prof: "Francesca Morini",
      team: (
        <>
          Partnerarbeit mit Anna&nbsp;Meide, Jona&nbsp;Pomerance & Quan&nbsp;Ta
        </>
      ),
      link: "https://uclab.fh-potsdam.de/swipe/",
      images: ["tts-1.png", "tts-2.png", "tts-3.png", "tts-4.png"],
      text: (
        <>
          <p>
            Ziel dieses Projekt&shy;wochen&shy;kurses war es, mithilfe eines
            speziellen Scrolly&shy;telling&shy;formates ein datengestützes
            Interview zu visualisieren. Das Ergebnis meines Teams zeigt die
            Touch-Interaktionen einer Instagram&shy;nutzerin während der
            Verwendung der App. Parallel dazu wird ein Interview gezeigt,
            welches die Interaktionen kommentiert und Entscheidungen, die
            während dem Benutzen der App getroffen werden, hinterfragt.
          </p>
          <p>
            Das experimentelle Visualisierungs&shy;konzept und der
            daten&shy;journalistische Ansatz in diesem Kurs gaben mir eine
            Vorstellung davon, welche spannenden Formen Daten&shy;visualisierung
            annehmen kann und wie Informations&shy;gestaltung völlig neuartige
            Erlebnisse schaffen kann. Durch meinen Teampartner Jona erkannte
            ich, wie unverzichtbar technisches Wissen bei der präzisen Umsetzung
            von Gestaltungs&shy;projekten mit Daten im Web ist.
          </p>
        </>
      ),
    },
    {
      id: "zeitmaschinen",
      project: "Down the Rabbit Hole",
      title: "Zeitmaschinen",
      course: "Zeitmaschinen",
      prof: "Prof. Boris Müller",
      team: <>Partnerarbeit mit Julia&nbsp;Zimmermann</>,
      link: "https://procrastination.honigmitoe.de/",
      images: [
        "zeitmaschinen-1.jpg",
        "zeitmaschinen-2.jpg",
        "zeitmaschinen-3.jpg",
        "zeitmaschinen-4.jpg",
        "zeitmaschinen-5.jpg",
      ],
      text: (
        <>
          <p>
            Im Mittelpunkt des Kurses stand das Thema Zeit. Zusammen mit meiner
            Team&shy;partnerin erarbeitete ich eine Website, die das Thema
            Pro&shy;krastination sowohl inhaltlich behandelt, als auch in der
            Gestaltung und Struktur der Seite aufgreift. Mithilfe verschiedener
            Abzweigungen navigieren Leser*innen durch einen virtuellen
            ‚Kaninchenbau‘, der neben den eigentlichen Inhalten viele verspielte
            Ablenkungen bereithält.
          </p>
          <p>
            Das offene Konzept der Projekt&shy;idee ermöglichte es uns
            experimen&shy;tell mit der Gestaltung umzugehen und in viele
            verschiedene Richtungen zu gehen. Das Projekt half mir dabei
            jenseits gestalterischer Konven&shy;tionen Möglich&shy;keiten im
            UI-Design auszutesten. Außerdem finde ich die Spannung zwischen
            linearer Erzählung und geleiteter Exploration bis heute ein
            faszi&shy;nierendes Feld im UI-Design.
          </p>
        </>
      ),
    },
  ],
  6: [
    {
      id: "wasserstoff",
      project: "Figures",
      title: "Praktikum – Wasserstoff",
      course: "Praktikum",
      prof: "Prof. Constanze Langer (Praktikumsbetreuung)",
      team: <>Teamarbeit mit Figures & Fabian&nbsp;Dinklage</>,
      link: "https://fraunhofer.wasserstoff-story.de/",
      images: [
        "wasserstoff-1.png",
        "wasserstoff-2.png",
        "wasserstoff-3.png",
        "wasserstoff-4.png",
      ],
      text: (
        <>
          <p>
            Bei der Suche nach einem Praktikums&shy;platz konzentrierte ich mich
            auf die Felder des Designs, die ich in den vorherigen Semestern am
            spannendsten fand. So war ich sehr froh, als ich eine Zusage des
            Berliner Design&shy;büros Figures erhielt, welche sich auf
            datenbasiertes Story&shy;telling im Web spezialisiert haben. Während
            meines Praktikums arbeitete ich hauptsächlich an einem Projekt für
            das Fraunhofer Institut zum Thema Wasser&shy;stoff. Dabei ging es
            darum, die Rolle von Wasser&shy;stoff in der zukünftigen
            Energie&shy;versorgung Deutsch&shy;lands zu beleuchten. Ich
            begleitete das Projekt in der Konzeption und übernahm das UI-Design,
            sowie die Gestaltung der Daten&shy;visualisierungen.
          </p>
          <p>
            Das Projekt war für mich sehr fordernd, da ich mich erstmals
            intensiver mit Sketch, Figma, D3 und Observable
            auseinander&shy;setzte. Außerdem war ich zum ersten Mal in der
            Situation, dass ich mit Program&shy;mierern zusammenarbeitete und
            mein Prototyp so auf&shy;bereitet sein musste, dass er als Entwurf
            für den Kunden und als Vorlage für das Develop&shy;ment
            funktioniert. Auch wenn ich im nachhinein vieles anders machen
            würde, habe ich durch diese Arbeit sowohl hand&shy;werklich als auch
            design&shy;theoretisch und in der Kommu&shy;nikation mit Kund*innen
            viel dazu gelernt.
          </p>
        </>
      ),
    },
    {
      id: "wortwahl",
      project: "Figures",
      title: "Praktikum – Wortwahl",
      course: "Praktikum",
      prof: "Prof. Constanze Langer (Praktikumsbetreuung)",
      team: <>Teamarbeit mit Figures</>,
      link: "https://wortwahl.figures.cc/",
      images: [
        "wortwahl-1.png",
        "wortwahl-2.png",
        "wortwahl-3.png",
        "wortwahl-4.png",
        "wortwahl-5.png",
        "wortwahl-6.png",
      ],
      text: (
        <>
          <p>
            Schon in den Vorstellungs&shy;gesprächen mit Figures legten wir
            fest, dass ich auch ein eigenes Projekt während meines Praktikums
            durchführen würde. Da in diesem Jahr die Bundes&shy;tags&shy;wahl
            anstand und gerade die neuen Wahl&shy;programme der Parteien
            veröffentlicht wurden, entschloss ich eine Arbeit über
            Wort&shy;häufigkeiten in den Pro&shy;grammen zu machen. Dabei
            konzentrierte ich mich auf Worte, die zum Themen&shy;feld ‚Klima‘
            gehören. Zum ersten mal erarbeitete ich eine
            Daten&shy;visuali&shy;sierung von der Konzeption, über die
            Daten&shy;sammlung, bis hin zu Design und Develop&shy;ment selbst.
            In jedem der Schritte bekam ich beratende Unter&shy;stützung vom
            Team.
          </p>
          <p>
            Besonders die Teile des Projekt&shy;prozesses die
            Program&shy;mierung erforderten, waren sehr lehr&shy;reich für mich.
            Die Auswertung der Wahl&shy;programme nahm viel Zeit in Anspruch, da
            diese nur als PDFs vorlagen und ich erst eine Pipeline erstellen
            musste, um daraus Wort&shy;häufigkeiten extra&shy;hieren zu können.
            Für das Web&shy;development arbeitete ich erstmals mit dem
            JavaScript Frame&shy;work React. Die erlernten Fähig&shy;keiten in
            der Program&shy;mierung gaben mir Sicher&shy;heit zukünftige
            Datavis-Projekte mehr nach meinen Vorstellungen durchführen zu
            können.
          </p>
        </>
      ),
    },
  ],
  7: [
    {
      id: "mapping",
      project: "macht mieten arm?",
      title: "Mapping Cities",
      course: "Mapping Cities – Making Cities",
      prof: "Prof. Dr. Marian Dörk",
      team: (
        <>
          Teamarbeit mit Clara-Sophie&nbsp;Mau, Judith&nbsp;Neidhardt,
          Mennatullah&nbsp;Hendawy & Sofia&nbsp;Helfrich
        </>
      ),
      link: "https://uclab.fh-potsdam.de/mapping/machtmietenarm/",
      images: [
        "mapping-1.png",
        "mapping-2.png",
        "mapping-3.png",
        "mapping-4.png",
        "mapping-5.png",
        "mapping-6.png",
        "mapping-7.png",
      ],
      text: (
        <>
          <p>
            Nach meinem Praktikum wollte ich tiefer in die Materie der
            Daten&shy;visualisierung einsteigen. Im Mapping Cities Kurs ging es
            darum, Daten von einem Kooperations&shy;partner zum Thema ‚Stadt‘ zu
            visualisieren. Zusammen mit meinen Team&shy;partner&shy;innen aus
            dem Studien&shy;gang Urbane Zukunft gestaltete ich eine
            Scrolly&shy;telling Website, die Miet&shy;preise in Deutschland in
            Relation zum Einkommen bestimmter gesellschaftlicher Gruppen
            visualisiert. Das Projekt soll zeigen warum deutsche Groß&shy;städte
            mehr günstigen Wohn&shy;raum brauchen.
          </p>
          <p>
            Da unsere Daten nur in stark verarbeiteter und spezifisch
            kontextuali&shy;sierter Form vorlagen, lieferte das Projekt sehr
            ein&shy;geschränkte Möglich&shy;keiten die Daten zusammen zu
            visualisieren und in den Kontext einer stringenten Story zu setzen.
            Diese Heraus&shy;forderung gemeinsam zu bewältigen, lehrte mich viel
            über die Arbeit im Team. Darüber hinaus konnte ich meine
            Fähig&shy;keiten im Programmieren mit React stark verbessern.
          </p>
        </>
      ),
    },
    {
      id: "networks",
      project: "Jobben als Abgeordnete*r",
      title: "Networks & Hierarchies",
      course: "Advances in Data Visualization: Networks & Hierarchies",
      prof: "Mark-Jan Bludau",
      team: "Einzelarbeit",
      link: "https://uclab.fh-potsdam.de/advances/content/networks2021/sidejobs/",
      images: [
        "networks-1.png",
        "networks-2.jpg",
        "networks-3.jpg",
        "networks-4.png",
      ],
      text: (
        <>
          <p>
            Der Kurs bei Mark-Jan Bludau beschäftigte sich mit der
            Visuali&shy;sierung von beziehung&shy;sorientierten Daten mithilfe
            von Netzwerk&shy;dar&shy;stellungen. In meinem Projekt visualisierte
            ich die Neben&shy;einkünfte von aktuellen deutschen
            Bundestags&shy;abgeordneten und ihre dadurch entstehende Beziehung
            zu Unternehmen. Die Daten bezog ich von der API von{" "}
            <a href="https://www.abgeordnetenwatch.de/api" target={"_blank"}>
              abgeordnetenwatch
            </a>
            .
          </p>
          <p>
            Auch wenn die entstandene Website in einigen Punkten nicht
            ausgereift ist, lernte ich in diesem Kurs viel über das
            Visuali&shy;sieren von komplexen Datenstrukturen. Anders als bei
            meinen bisherigen Visuali&shy;sierungen, arbeitete ich mit einem
            explorativen Ansatz, was mich dazu zwang mich mit der Kombination
            verschiedener Filter&shy;möglichkeiten auseinander&shy;zusetzen.
            Mein größtes Learning bestand jedoch im Umgang mit einer API und dem
            Verarbeiten, Filtern und Aufbereiten großer Datenmengen.
          </p>
        </>
      ),
    },
  ],
  8: [
    {
      id: "citizen",
      project: "Berliner Gedenktafeln",
      title: "Citizen Science",
      course: "Citizen Science – Stadtdaten visualisieren",
      prof: "Lucas Vogel",
      team: "Einzelarbeit",
      link: "https://gedenktafeln.honigmitoe.de/",
      images: [
        "citizen-1.png",
        "citizen-2.png",
        "citizen-3.png",
        "citizen-4.png",
        "citizen-5.png",
      ],
      text: (
        <>
          <p>
            Citizen Science ist ein Ansatz bei dem Laien aus der
            Zivil&shy;gesellschaft an Forschungs&shy;projekten mitarbeiten. In
            unserem Seminar beschäftigten wir uns mit den Merkmalen von Citizen
            Science und den möglichen Rollen von Designer*innen in solchen
            Projekten. Für meine praktische Arbeit entwickelte ich eine
            Visuali&shy;sierung, die einen umfangreichen Datensatz zu
            Gedenk&shy;tafeln in Berlin zeigt und mithilfe eines
            Scrolly&shy;tellings in die Themen Berliner Erinnerungs&shy;kultur
            einführt.
          </p>
          <p>
            Um noch mehr Insights zu generieren erweiterte ich den bestehenden
            Datensatz mithilfe eines eigenen Scripts zum Scrapen von
            Wikipedia-Daten, was sehr zeitaufwendig war. Dennoch war es mir
            durch meine Vorerfahrung erstmals möglich so ein umfangreiches
            Projekt in kurzer Zeit alleine zu realisieren. Die beratende
            Unterstützung in Coding-Fragen seitens des Kurses und des Dozenten
            gaben mir zusätzliche Sicher&shy;heit in der Projektumsetzung.
          </p>
        </>
      ),
    },
    {
      id: "kdm",
      project: "Verschwundene Dörfer",
      title: "Karten der Macht",
      course: "Karten der Macht. Macht der Karten.",
      prof: "Prof. Dr. Sebastian Meier",
      team: "Einzelarbeit",
      link: "https://verschwundene-ortschaften.honigmitoe.de/",
      images: [
        "kdm-1.png",
        "kdm-2.png",
        "kdm-3.png",
        "kdm-4.png",
        "kdm-5.png",
        "kdm-6.png",
      ],
      text: (
        <>
          <p>
            Da ich mich bis dahin noch nie mit Karten&shy;visualisierungen
            beschäftigt hatte, wählte ich diesen Kurs, welcher sich mit der
            Wirk&shy;mächtigkeit von Karten auseinander&shy;setzte. Bei meinem
            Projekt zur Gestaltung und Produktion einer Karte, recherchierte ich
            Daten zu Dörfern, die durch den Tagebau in Deutschland zerstört
            wurden. Innerhalb eines Scrollytelling-Formats visualisierte ich die
            Dörfer und Tagebaue mit einer Karten&shy;darstellung und Bildern zur
            Tagebau&shy;historie in Deutschland.
          </p>
          <p>
            Die Begrenzt&shy;heit der Datenlage zwang mich dazu ein Bubble-Chart
            in der Visuali&shy;sierung zu verwenden, um die Ungewissheit der
            genauen Verortung der Dörfer adäquat zu visualisieren. Mein
            Interesse für das Thema war und ist sehr groß, was mir dabei half
            das Projekt bis ins Detail auszuarbeiten. Erstmals fühlte ich mich
            in der Bearbeitung eines Datavis-Projektes und dessen
            End&shy;ergebnis sehr wohl und denke, dass das Projekt meine bisher
            erworbenen Fähig&shy;keiten auf dem Gebiet gut repräsentiert.
          </p>
        </>
      ),
    },
  ],
};
