import React, { useState } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import { colors } from "../models";

interface NavProps {
  width: number;
  handleBurgerMenu: any;
}

const FixedMenuWrapper = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1100;
`;

const Burger = ({ handleBurgerMenu, width }: NavProps) => {
  const burgerWidth = width >= 768 ? 30 : 20;
  const burgerHeight = width >= 768 ? 20 : 15;

  const createBurger = () => {
    const strokeWidth = 3;
    const margins = {
      top: strokeWidth / 2,
      right: strokeWidth / 2,
      bottom: strokeWidth / 2,
      left: strokeWidth / 2,
    };
    const chartWidth = burgerWidth - margins.left - margins.right;
    const chartHeight = burgerHeight - margins.top - margins.bottom;

    return (
      <>
        <rect
          x={0}
          y={0}
          width={burgerWidth}
          height={burgerHeight}
          fill="transparent"
          cursor={"pointer"}
          onClick={() => handleBurgerMenu()}
        />
        <g
          transform={`translate(${margins.left},${margins.top})`}
          pointerEvents="none"
        >
          <line
            id={"first-line"}
            x1={0}
            y1={0}
            x2={chartWidth}
            y2={0}
            stroke={colors.black}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
          <line
            id={"second-line"}
            x1={0}
            y1={chartHeight / 2}
            x2={chartWidth}
            y2={chartHeight / 2}
            stroke={colors.black}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
          <line
            id={"third-line"}
            x1={0}
            y1={chartHeight}
            x2={chartWidth}
            y2={chartHeight}
            stroke={colors.black}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        </g>
      </>
    );
  };

  return (
    <FixedMenuWrapper>
      <svg width={burgerWidth} height={burgerHeight}>
        {createBurger()}
      </svg>
    </FixedMenuWrapper>
  );
};

export default Burger;
