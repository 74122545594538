import * as React from "react";
import { colors } from "../../models";

interface Props {
  active: boolean;
  hover: boolean;
  diameter: string;
}

export const ButtonLeft = ({ active, hover, diameter }: Props) => (
  <svg
    width={diameter}
    height={diameter}
    viewBox="0 0 42 43"
    fill="none"
    cursor={active ? "pointer" : "auto"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="21"
      cy="21.8389"
      r="20"
      stroke={
        active
          ? hover
            ? colors.highlight
            : colors.white
          : colors.whiteDeselect
      }
      strokeWidth="2"
    />
    <path
      d="M11.4928 21.1318C11.1023 21.5223 11.1023 22.1554 11.4928 22.546L17.8568 28.9099C18.2473 29.3005 18.8805 29.3005 19.271 28.9099C19.6615 28.5194 19.6615 27.8862 19.271 27.4957L13.6142 21.8389L19.271 16.182C19.6615 15.7915 19.6615 15.1583 19.271 14.7678C18.8805 14.3773 18.2473 14.3773 17.8568 14.7678L11.4928 21.1318ZM12.2 22.8389H29.8V20.8389H12.2V22.8389Z"
      fill={
        active
          ? hover
            ? colors.highlight
            : colors.white
          : colors.whiteDeselect
      }
    />
  </svg>
);
