import * as React from "react";

export const BigArrowDown = () => (
  <svg
    width={"100%"}
    height={"100%"}
    viewBox="0 0 343 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M314.648 0H28.113V88.0036H0.729248L171.381 166.172L342.032 88.0037L314.648 88.0037V0Z"
      fill="white"
    />
  </svg>
);
