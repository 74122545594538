import * as React from "react";
import type { HeadFC } from "gatsby";
import "../css/global.css";
import styled from "styled-components";
import { colors } from "../models";
import favicon from "../images/favicon.png";
import { HeroSection, MainSection } from "../components/sections";
import { Helmet } from "react-helmet";
import Burger from "../components/burger";

const Main = styled.main`
  background: ${colors.black};
`;

const IndexPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "de",
        }}
      >
        <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
        />
      </Helmet>
      <Main>
        <HeroSection />
        <MainSection />
      </Main>
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Werkschau</title>;
